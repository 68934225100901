body {
  font-family: "Plus Jakarta Display", sans-serif;
  color: #50507e;
  font-size: 16px;
  line-height: 1.687em;
  letter-spacing: -0.01em;
  margin: 32px;
}

h1 {
  margin-top: 0px;
  margin-bottom: 20px;
  color: #070721;
  font-size: 48px;
  line-height: 1.291em;
  font-weight: 700;
  letter-spacing: -0.02em;
  text-shadow: 0 3px 6px rgba(48, 44, 255, 0.11);
}

h2 {

  margin-top: 0px;
  margin-bottom: 16px;
  color: #070721;
  font-size: 38px;
  line-height: 1.368em;
  font-weight: 700;
  letter-spacing: -0.02em;
  text-shadow: 0 3px 6px rgba(48, 44, 255, 0.11);
}

h3 {

  margin-top: 0px;
  margin-bottom: 11px;
  color: #070721;
  font-size: 24px;
  line-height: 1.416em;
  font-weight: 700;
  letter-spacing: -0.02em;
}


h4 {

  margin-top: 0px;
  margin-bottom: 11px;
  color: #070721;
  font-size: 22px;
  line-height: 1.545em;
  font-weight: 700;
  letter-spacing: -0.02em;
}

h5 {

  margin-top: 0px;
  margin-bottom: 11px;
  color: #070721;
  font-size: 18px;
  line-height: 1.555em;
  font-weight: 700;
  letter-spacing: -0.02em;
}

h6 {

  margin-top: 0px;
  margin-bottom: 10px;
  color: #070721;
  font-size: 16px;
  line-height: 1.625em;
  font-weight: 700;
}

p {
  margin-bottom: 0px;
}

a {
  transition: color 350ms ease;
  color: #302cff;
  text-decoration: underline;
}

a:hover {
  color: #070721;
}

ul {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 40px;
}

ol {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 40px;
}

li {
  margin-bottom: 14px;
}

img {
  display: inline-block;
  max-width: 100%;
}

label {
  display: block;
  margin-bottom: 10px;
  color: #070721;
  font-weight: 500;
}

strong {
  color: #070721;
  font-weight: 700;
}

blockquote {
  margin-bottom: 10px;
  padding: 50px 80px;
  border-style: solid;
  border-width: 0.5px 0.5px 0.5px 11px;
  border-color: #cecddd #cecddd #cecddd #302cff;
  border-radius: 8px 12px 12px 8px;
  background-color: #fff;
  box-shadow: 0 3px 12px 0 rgba(48, 44, 255, 0.05);
  color: #302cff;
  font-size: 22px;
  line-height: 1.636em;
  font-weight: 500;
  letter-spacing: -0.02em;
  max-width: 550px;
}



.highlight {
  margin-top: 0px;
  margin-bottom: 80px;
  color: #302cff;
  font-weight: 500;
}

.colorcode {
  margin: 6px;
  color: #302cff;
  font-weight: 500;
}

.large {
  margin-bottom: 0px;
  font-size: 20px;
  line-height: 1.7em;
}

.color-block {
  height: 80px;
  min-width: 100px;
}

.space {
  margin-top: 80px;
}

.color-palette {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.card {
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(17, 10, 99, 0.07),
    0 12px 22px 0 rgba(128, 118, 247, 0.07);
  margin: 10px;
  transition: 0.3s;
}
.card .highlight {
  padding: 10px;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(17, 10, 99, 0.2),
    0 12px 22px 0 rgba(128, 118, 247, 0.2);
}

.monospace {
  font-family: monospace;
}